<template>
   <center>
      <v-btn-toggle 
         mandatory
         :style="{ 
            'background-color': `${color}`, 
            'color': getOppositeColor(color) 
         }"
         :value="typeActivity" 
         @change="handleChangeType"
      >
         <v-btn
            :disabled="disabled"
            :style="{ 
               'background-color': `${color}`, 
               'color': getOppositeColor(color) 
            }"
         >
            <span :style="{ 'color': getOppositeColor(color) }">{{$t('common.all', locale)}}</span>
         </v-btn>
         <v-btn
            :disabled="disabled"
            :style="{ 
               'background-color': `${color}`, 
               'color': getOppositeColor(color) 
            }"
         >
            <v-icon 
               :style="{ 'color': getOppositeColor(color) }"
               small
            >
               mdi-human-child
            </v-icon>
         </v-btn>
         <v-btn
            :disabled="disabled"
            :style="{ 
               'background-color': `${color}`, 
               'color': getOppositeColor(color) 
            }"
         >
            <v-icon 
               :style="{ 'color': getOppositeColor(color) }"
            >
               mdi-human-male
            </v-icon>
         </v-btn>
     </v-btn-toggle>
   </center>
</template>
<script>
import utils from '@/services/utils'
export default {
   props: {
      disabled: {
         type: Boolean,
         default: false, 
      },
      color: {
         type: String,
         required: true,
      },
      locale: {
         type: String,
         default: null,
      },
      typeActivity: {
         type: Number,
         default: null,
      },
      onChange: {
         type: Function,
         required: true,
      },
   },
   methods: {
      getOppositeColor (v) {
         return utils.getOppositeColor(v)
      },
      handleChangeType (v) {
         this.onChange(v)
      },
   },
}
</script>

