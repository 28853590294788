var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('center',[_c('v-btn-toggle',{style:({ 
         'background-color': ("" + _vm.color), 
         'color': _vm.getOppositeColor(_vm.color) 
      }),attrs:{"mandatory":"","value":_vm.typeActivity},on:{"change":_vm.handleChangeType}},[_c('v-btn',{style:({ 
            'background-color': ("" + _vm.color), 
            'color': _vm.getOppositeColor(_vm.color) 
         }),attrs:{"disabled":_vm.disabled}},[_c('span',{style:({ 'color': _vm.getOppositeColor(_vm.color) })},[_vm._v(_vm._s(_vm.$t('common.all', _vm.locale)))])]),_c('v-btn',{style:({ 
            'background-color': ("" + _vm.color), 
            'color': _vm.getOppositeColor(_vm.color) 
         }),attrs:{"disabled":_vm.disabled}},[_c('v-icon',{style:({ 'color': _vm.getOppositeColor(_vm.color) }),attrs:{"small":""}},[_vm._v(" mdi-human-child ")])],1),_c('v-btn',{style:({ 
            'background-color': ("" + _vm.color), 
            'color': _vm.getOppositeColor(_vm.color) 
         }),attrs:{"disabled":_vm.disabled}},[_c('v-icon',{style:({ 'color': _vm.getOppositeColor(_vm.color) })},[_vm._v(" mdi-human-male ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }